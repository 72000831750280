<template>
  <div class="inform">
    <div class="inform_top">
      <div class="left">
        <img :src="cancel" @click="toBack" alt />
      </div>
      <div class="center" @click="showStudentListFn">
        {{studentName}}出入记录
        <van-icon v-if="isClassManager" name="arrow-down" />
      </div>
    </div>
    <div class="records_date">
      <div class="date_in" @click="showDate=true">
        <div class="left">
          <p>{{day}}</p>
          <p>{{year}}-{{month}}</p>
        </div>
        <div class="right">
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="records_content">
      <div class="content" v-if="studentList.length !== 0">
        <div class="title">学生</div>
        <ul class="detail" v-for="(item,index) in studentList" :key="index">
          <li>
            <p>打卡时间</p>
            <p>{{item.dateTime}}</p>
          </li>
          <li>
            <p>打卡照片</p>
            <p>
              <img :src="item.picture?item.picture:none" @click="previewImageFn(item.picture)" alt />
            </p>
          </li>
        </ul>
      </div>
      <div class="content" v-if="allTransferList.length !== 0">
        <div class="title">家长</div>
        <ul class="detail" v-for="(item,index) in allTransferList" :key="index">
          <li>
            <p>打卡时间</p>
            <p>{{item.dateTime}}</p>
          </li>
          <li>
            <p>打卡照片</p>
            <p>
              <img :src="item.picture?item.picture:none" @click="previewImageFn(item.picture)" alt />
            </p>
          </li>
          <li>
            <p>打卡方式</p>
            <p>{{item.type === 0?'固定接送人' : item.type === 1?'临时接送人' : '接送人已删除'}}</p>
          </li>
        </ul>
      </div>
    </div>
    <van-popup v-model="showDate" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="dateConfirm"
        @cancel="showDate=false"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup v-model="showStudentList" round position="bottom">
      <van-picker
        show-toolbar
        title="选择学生"
        :columns="columns"
        @cancel="showStudentList = false"
        @confirm="selectStudentConfirm"
      />
    </van-popup>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import cancel from "@/assets/cancel.png";
import none from "@/assets/images/photo_none.png";
import right from "@/assets/right.png";
import { getStudentsListAPI, getTransferRecordsListAPI } from "@/api/pick-up";
export default {
  data() {
    return {
      cancel,
      right,
      none,
      studentsList: [],
      filters: {
        userId: "",
        time: "",
      },
      classId: "",
      isClassManager: false,
      studentName: "",
      list: [],
      allTransferList: [],
      studentList: [],
      showDate: false,
      currentDate: new Date(),
      day: "",
      month: "",
      year: "",
      showStudentList: false,
      columns: [],
      show: false,
    };
  },
  created() {
    this.filters.time = this.getNowFormatDate();
    if (this.$route.query.userId && this.$route.query.classId) {
      this.isClassManager = true;
      this.classId = this.$route.query.classId;
      this.filters.userId = this.$route.query.userId;
      this.getStudentsListFn();
    } else {
      const user = JSON.parse(sessionStorage.getItem("personInfo"));
      this.filters.userId = user.id;
      this.studentName = user.name;
      this.getList();
    }
  },
  methods: {
    toPage(name) {
      this.$router.push({ name });
    },
    getStudentsListFn() {
      getStudentsListAPI(this.classId).then((res) => {
        if (res.code === 0) {
          this.studentsList = res.data;
          this.studentsList.map((item) => {
            this.columns.push(item.name);
            if (item.id === Number(this.filters.userId)) {
              this.studentName = item.name;
            }
          });
          this.getList();
        }
      });
    },
    getList() {
      this.show = true;
      getTransferRecordsListAPI(this.filters)
        .then((res) => {
          if (res.code === 0) {
            console.log(111);
            this.allTransferList = res.data.allTransferList;
            this.studentList = res.data.studentList;
            this.show = false;
          }
        })
        .catch(() => {
          this.show = false;
        });
    },
    getNowFormatDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0
      this.day = strDate;
      this.year = year;
      this.month = month;
      return `${year}-${month}-${strDate}`;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    dateConfirm(time) {
      let year = time.getFullYear();
      let month =
        time.getMonth() + 1 < 10
          ? `0${time.getMonth() + 1}`
          : time.getMonth() + 1;
      let day = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
      this.filters.time = `${year}-${month}-${day}`;
      this.day = day;
      this.month = month;
      this.year = year;
      this.getList();
      this.showDate = false;
    },
    selectStudentConfirm(value, index) {
      this.studentName = value;
      this.filters.userId = this.studentsList[index].id;
      this.getList();
      this.showStudentList = false;
    },
    previewImageFn(data) {
      ImagePreview([data ? data : none]);
    },
    showStudentListFn() {
      if (!this.isClassManager) {
        return;
      }
      this.showStudentList = true;
    },
    toBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang='scss' scoped>
.inform {
  .inform_top {
    height: 48px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #ddd;
    .left {
      position: absolute;
      left: 1rem;
      img {
        // width: 35px;
        height: 30px;
      }
    }
  }
  .records_date {
    position: fixed;
    width: 100%;
    top: 48px;
    height: 48px;
    background: rgba(241, 241, 241);
    .date_in {
      padding-top: 5px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        p {
          margin: 0;
          text-align: center;
          color: #000;
          font-size: 0.9rem;
        }
        p:first-child {
          font-size: 1.1rem;
          font-weight: 900;
        }
      }
      .right {
        margin-left: 5px;
      }
    }
  }
  .records_content {
    margin-top: 96px;
    .content {
      width: 98%;
      background: #fff;
      margin: 0 auto;
      margin-bottom: 10px;
      border-radius: 5px;
      .title {
        color: black;
        font-size: 1rem;
        font-weight: 900;
        margin-left: 10px;
        padding-top: 10px;
      }
      ul {
        li {
          height: 35px;
          font-size: 0.8rem;
          text-indent: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 1rem;
          p {
            margin: 0;
            img {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>